import React from "react"
import styled from "styled-components"
import smoothscroll from "smoothscroll-polyfill"
import { color } from "../../layout/global"
import { Link } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

if (typeof window !== `undefined`) {
  smoothscroll.polyfill()
}

const Configurador = ({ configuradoresContent }) => {
  const breakpoints = useBreakpoint()

  return (
    <ConfiguradorStyled>
      <div className="container" id="container">
        <div className="title-container">
          <div className="title" dangerouslySetInnerHTML={{ __html: configuradoresContent.title }}></div>
          <div className="subtitle" dangerouslySetInnerHTML={{ __html: configuradoresContent.subtitle }}></div>
        </div>

        <div className="configuradores">
          {configuradoresContent["configuradores"].map(configurador => (
            <div className="image" key={configurador.link}>
              <Link className="link-configurador" to={configurador.link}>
                {!breakpoints.tl && <img className="static" src={configurador.img} alt="configurador-img" />}
                <img className="active" src={configurador.gif} alt="configurador-gif" />
              </Link>
            </div>
          ))}
        </div>
      </div>
    </ConfiguradorStyled>
  )
}

export default Configurador

const ConfiguradorStyled = styled.section`
  display: flex;
  align-items: center;
  max-width: 1920px;
  margin: 0 auto;
  padding: 150px 3%;
  position: relative;
  z-index: 3;
  background-color: black;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 150px 3%;
  }

  .title {
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 4rem;
    color: ${color.white};
    text-align: center;

    @media (max-width: 900px) {
      font-size: 3rem;
    }

    @media (max-width: 768px) {
      font-size: 2.25rem;
      text-align: center;
    }
  }

  .subtitle {
    font-family: "Financier", sans-serif;
    font-weight: 200;
    font-style: italic;
    font-size: 5rem;
    line-height: 1.25;
    color: ${color.red};
    margin-bottom: 9rem;
    text-align: center;

    @media (max-width: 900px) {
      font-size: 4rem;
      margin-bottom: 6rem;
    }

    @media (max-width: 768px) {
      font-size: 3rem;
      text-align: center;
      margin-bottom: 5rem;
    }
  }

  .configuradores {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 6vw;

    .image {
      position: relative;
      /* padding:2vw; */

      img {
        max-height: 315px;
      }

      .static {
        position: absolute;
      }

      .static:hover {
        opacity: 0;
      }
    }
  }
`
