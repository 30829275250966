import React, { useEffect } from "react"
import Layout from "../layout"
import { graphql, navigate } from "gatsby"
import SEO from "../components/SEO"
import FormContact from "../components/FormContact"
import Configurador from "../components/Configuradores"

import { useTranslation, I18nextContext } from 'gatsby-plugin-react-i18next'


const Configuradores = ({ location }) => {
  const { t } = useTranslation()
  const activeLanguage = React.useContext(I18nextContext).language
  if (activeLanguage == 'br' ) {
    useEffect(() => {
      navigate('/404/');
    }, []);
    return null;
  } else
    return (
      <Layout
        location={location}
        footer={t("footer", { returnObjects: true })}
        header={t("header", { returnObjects: true })}
        hideBr
      >
        <SEO title="Configuradores" bodyClass={"configurador"} 
        domain={location?.hostname}></SEO>
        <Configurador
          configuradoresContent={t('configuradoresHome', { returnObjects: true })}
        />
        <FormContact id="form-contact" info={t("form", { returnObjects: true })} lang={activeLanguage} location={location} />
      </Layout>
    )
}

export default Configuradores

export const QueryHomepagePT = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["home", "form", "footer", "header"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`